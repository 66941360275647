import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Api from '../apis';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import MainLayout from '../components/MainLayout';
import ProductCard from '../components/ProductCard';
import { getUrlParams } from '../helpers/location';
import withAuth from '../hooks/withAuth';
import { ProductType } from '../helpers/constants';
import SearchInput from '../components/SearchInput';
import { navigate } from 'gatsby';

const useStyles = makeStyles(theme => ({
  coverImg: {
    width: '100%',
  },
  header: {
    padding: 0,
    margin: '5px 0px',
  },
  red: {
    color: 'red',
  },
  category: {
    margin: '5px 10px',
    color: '#3783e4',
  },
  divider: {
    margin: 15,
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const ProductListPage = ({ location }) => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(null);
  const query = getUrlParams(location.search);
  const {
    user_question_id = '',
    category_id = '',
    product_type = 'product',
  } = query;

  useEffect(() => {
    Api.postCountData(product_type, user_question_id);
    if (category_id) {
      Api.getCategoryById(category_id).then(data => {
        setCategory(data);
      });
    }
    fetchProducts();
  }, []);

  function handleClick(product) {
    const { id, url, product_type } = product;
    Api.updateCountProduct(id).then(() => {
      if (product_type === ProductType.PRODUCT) {
        window.location = url;
      } else if (product_type === ProductType.PACKAGE) {
        navigate(
          `/package?product_id=${id}&user_question_id=${user_question_id}&category_id=${category_id}`,
        );
      }
    });
  }

  function fetchProducts(text) {
    Api.getProducts(category_id, text).then(result => {
      setProducts(result.results);
    });
  }

  function onSearch(text) {
    fetchProducts(text);
  }

  return (
    <MainLayout>
      <div style={{ padding: 10, maxWidth: 500 }}>
        <SearchInput
          onSearch={onSearch}
          placeholder='ชื่อโปรแกรม ชื่อสินค้าและบริการ ชื่อโรงพยาบาล'
        />
      </div>
      <Divider className={classes.divider} />
      <h3 className={classes.category}>{category && category.name}</h3>
      <Grid container>
        {products.map(product => {
          return (
            <Grid item xs={12} sm={4} md={3} lg={3} key={product.id}>
              <Box
                p={1}
                key={product.id}
                display='flex'
                justifyContent='center'
              >
                <ProductCard
                  {...product}
                  onClick={() => handleClick(product)}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </MainLayout>
  );
};

export default withAuth(ProductListPage);
